


























import Vue from "vue";

import AccountsIcon from "@/icons/Accounts.vue";
import BackupsIcon from "@/icons/Backups.vue";
import MessagesIcon from "@/icons/Messages.vue";
import NavigatingButton from "@/components/NavigatingButton.vue";
import ReportsIcon from "@/icons/Reports.vue";
import SignupsIcon from "@/icons/Signups.vue";

export default Vue.extend({
  name: "Home",
  components: {
    AccountsIcon,
    BackupsIcon,
    MessagesIcon,
    NavigatingButton,
    ReportsIcon,
    SignupsIcon
  },
  computed: {
    accountCount(): number {
      return Object.values(this.$store.direct.state.accounts.all).length;
    },
    backupCount(): number {
      return Object.values(this.$store.direct.state.backups.all).length;
    },
    messagesCount(): number {
      return this.$store.direct.getters.unarchivedMessagesCount;
    }
  }
});
