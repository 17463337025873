












import Vue from "vue";

export default Vue.extend({
  name: "NavigatingButton",
  props: {
    to: { type: String, default: "" },
    number: { type: Number, default: null }
  }
});
